export const LANDING = '/';
export const ABOUT = '/about';
export const VENDORS = '/vendors';
export const SUPPORT = '/support';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const VENDOR = '/vendor';
export const VENDOR_DETAILS = '/vendor/:id';
export const VENDOR_EDIT = '/vendor/edit/:id';
export const VENDOR_NEW = '/vendor/new';
export const EVENTS = '/events';
export const EVENT_EDIT = '/events/edit/:id';
export const EVENT_NEW = '/events/new';
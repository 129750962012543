export const LANDING = 'Fair Food Finder';
export const ABOUT = 'About | Fair Food Finder';
export const VENDORS = 'Vendors | Get listed on the Fair Food Finder';
export const SUPPORT = 'Support & Contact | Fair Food Finder';
export const SIGN_UP = 'Vendor Sign up | Fair Food Finder';
export const SIGN_IN = 'Vendor Sign in | Fair Food Finder';
export const HOME = 'Home | Vendor Portal';
export const ACCOUNT = 'Account | Vendor Portal';
export const PASSWORD_FORGET = 'Forgot Password | Fair Food Finder';
export const ADMIN = 'Admin | Vendor Portal';
export const ADMIN_DETAILS = 'Admin Details | Vendor Portal';
export const VENDOR = '/vendor';
export const VENDOR_DETAILS = '/vendor/:id';
export const VENDOR_NEW = 'New Food Stand | Vendor Portal';
export const VENDOR_EDIT = 'Edit Food Stand | Vendor Portal';
export const EVENTS = 'Events | Vendor Portal';
export const EVENT_NEW = 'New Event | Vendor Portal';
export const EVENT_EDIT = 'Edit Event | Vendor Portal';